import React, { useState } from 'react'
import Layout from '../components/layout';
import Seo from "../components/SEO/Seo"
import "../styles/contacto.scss"
import TextareaAutosize from 'react-textarea-autosize';
import AutosizeInput from 'react-input-autosize';

import { IconContext } from "react-icons";

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Contacto = (props) => {


    const [nombre, setNombre] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [email, setEmail] = useState("");
    const [telefono, setTelefono] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [mensajeError, setMensajeError] = useState("");
    const [respuesta, setRespuesta] = useState("");

    const { executeRecaptcha } = useGoogleReCaptcha()
    // const [token, setToken] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            return
        }

        // This is the same as grecaptcha.execute on traditional html script tags
        const result = await executeRecaptcha('contacto')
        //--> grab the generated token by the reCAPTCHA
        // Prepare the data for the server, specifically body-parser

        const data = JSON.stringify({ nombre, empresa, email, telefono, mensaje, result })
        // POST request to your server

        fetch(`${process.env.API_URL}/contactoagencias`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-type': 'application/json'
            },
            body: data //--> this contains your data
        })
            .then(res => res.json())
            .then(data => {
                const success = data.success
                const score = data.score

                if (success && score >= 0.7) {
                    setMensajeError("Gracias por ponerte en contacto con nosotros")
                    setRespuesta("correcto");
                    setNombre("")
                    setEmpresa("")
                    setEmail("")
                    setTelefono("")
                    setMensaje("")
                    setIsChecked(false)
                } else {
                    setMensajeError("Ha habido un error en el envío del formulario, por favor inténtalo de nuevo")
                    setRespuesta("error")
                }
            })
    }

    return (
        <div>
            <Layout>
                <Seo title="Contacto" pathname={props.location.pathname} />
                <div className="primera-seccion cuerpo-contacto container">
                    <h1><span className="subrayado">Dinos</span> a lo que te enfrentas, lo pensamos y te <span className="subrayado">llamamos</span></h1>
                    <div className="caja-formulario">
                        <form className="form-contacto" method="post" onSubmit={handleSubmit}>
                            <div>Hola, mi nombre es
                                <AutosizeInput
                                    name="nombre"
                                    placeholder="nombre"
                                    required
                                    value={nombre}
                                    onChange={e => setNombre(e.target.value)}
                                    style={{ fontFamily: "Now-black-light" }}
                                    inputStyle={{ fontFamily: "Now-black-light", fontWeight: "bold" }}
                                />
                                y trabajo para
                                <AutosizeInput
                                    name="empresa"
                                    placeholder="mi empresa"
                                    required
                                    value={empresa}
                                    onChange={e => setEmpresa(e.target.value)}
                                    style={{ fontFamily: "Now-black-light" }}
                                    inputStyle={{ fontFamily: "Now-black-light", fontWeight: "bold" }}
                                />
                                , para localizarme podéis enviarme un email a
                                <AutosizeInput
                                    type="email"
                                    name="email"
                                    placeholder="mi email"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    style={{ fontFamily: "Now-black-light" }}
                                    inputStyle={{ fontFamily: "Now-black-light", fontWeight: "bold" }}
                                />
                                o llamarme al
                                <AutosizeInput
                                    type="tel"
                                    name="telefono"
                                    placeholder="nº teléfono"
                                    maxLength="14"
                                    minLength="9"
                                    required
                                    value={telefono}
                                    onChange={e => setTelefono(e.target.value)}
                                    style={{ fontFamily: "Now-black-light" }}
                                    inputStyle={{ fontFamily: "Now-black-light", fontWeight: "bold" }}
                                />
                            </div>
                            <br />
                            <div>

                                El proyecto o la cuestión por la que contacto con vosotros es:
                                <TextareaAutosize
                                    required
                                    name="mensaje"
                                    id="mensaje"
                                    placeholder="Escribe aquí tu mensaje..."
                                    value={mensaje}
                                    onChange={e => setMensaje(e.target.value)}
                                />
                            </div>
                            <br />
                            <div className="caja-checkbox">
                                <input
                                    type="checkbox"
                                    id="privacidad"
                                    name="privacidad"
                                    // defaultChecked={isChecked}
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <label htmlFor="privacidad" className="check-box"></label>
                                <label htmlFor="privacidad">He leído y acepto la política de privacidad</label>
                            </div>

                            <div className={`mensaje-formulario
                                ${respuesta}                              
                            `} dangerouslySetInnerHTML={{ __html: mensajeError }} />

                            <IconContext.Provider value={{ color: "#1f1f1f" }}>
                                <div className="icono-input">
                                    <input
                                        type="submit"
                                        value="Enviar"
                                        className="boton-enviar"
                                        disabled={!isChecked}
                                    />
                                </div>
                            </IconContext.Provider>
                        </form>
                        <p><small className="texto-recaptcha">Este sitio está protegido por reCAPTCHA y se aplican la <a href="https://policies.google.com/privacy">política de privacidad</a> y los <a href="https://policies.google.com/terms">términos del servicio</a> de Google.</small></p>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default Contacto;
